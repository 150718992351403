<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab v-if="listLocations.length > 0">
        <template #title>
          <i class="fa fa-camera" /> Photo Stops / <i class="fa fa-eye" /> Pass Bys
        </template>
        <scroll-pane>
          <b-list-group flush>
            <b-list-group-item v-for="(location, index) of listLocations" class="small m-0 pl-2" :key="index">
              <fa v-if="location.icon" :icon="location.icon"/> <b>{{location.name}}</b>
              <a :href="locationMapsLink(location)" target="_blank">
                <wrapped-text :text="location.address"></wrapped-text>
              </a>
            </b-list-group-item>
          </b-list-group>
        </scroll-pane>
      </b-tab>
      <b-tab @click="activateMap">
        <template #title>
          <i class="fa fa-map-marker"></i> Map
        </template>
        <div class="border-default border" :id="id" style="min-height: 300px">
          <b-spinner v-if="googleLoading" style="margin-top: 145px; margin-left: 45%" />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Prop, Watch} from 'vue-property-decorator'
import {Location, Tour, TourStaff} from "@eguide/api";
  import * as _ from 'lodash'
  import {google} from 'google-maps'
  import Axios from "axios";
import WrappedText from "@hc/ui/util/components/WrappedText.vue";
import ScrollPane from "@hc/ui/util/components/ScrollPane.vue";
@Component({
  components: {ScrollPane, WrappedText}
})
export default class GoogleMaps extends Vue {

  @Prop({type: Object, required: true}) tour!: Tour
  @Prop({type: Object, required: false}) tourStaff!: TourStaff

  id = `${Math.random()}`
  map = null
  bounds = null
  displayedLocations = []
  googleLoading = true

  mounted(){
    this.activateMap()
  }

  @Watch('locations')
  async locationsChanged() {
    if(!this.$google) return
    await this.updateLocations()
  }

  async activateMap() {
    let failSaveCounter = 20
    while(!this.$google && failSaveCounter > 0){
      await new Promise((r)=>setTimeout(r, 500))
      failSaveCounter -= 1
    }
    if(failSaveCounter == 0 && !this.$google){
      console.error("Google Maps did not load")
      return
    }
    this.googleLoading = false
    if(!this.bounds) await this.init()
    this.$nextTick( () => this.map.fitBounds(this.bounds) )
  }

    async init() {
      this.bounds = new this.$google.maps.LatLngBounds();
      this.map = new this.$google.maps.Map(document.getElementById(this.id), {
        //center: {lat: 52.5145466, lng: 13.3479302},
        //zoom: 13,
        maxZoom: 17,
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        styles: [
          {elementType:'all', stylers:[{saturation:-100}]},
          {elementType:'all', featureType:'landscape', stylers:[{color:'#ffffff'}]},
          {elementType:'geometry.fill', featureType:'poi.park', stylers:[{color:'#ffebd7'}]},
          {elementType:'geometry.stroke', featureType:'poi.park', stylers:[{color:'#e4c3a3'}]},
          {elementType:'geometry.fill', featureType:'road', stylers:[{color:'#e4c3a3'}]},
          {elementType:'geometry.stroke', featureType:'road', stylers:[{color:'#e4a96e'}]},
          {elementType:'geometry.fill', featureType:'water', stylers:[{color:'#b9d6dc'}]},
          {elementType:'geometry.stroke', featureType:'water', stylers:[{color:'#92cfdc'}]},
          {featureType: "poi", elementType: "labels", stylers: [{ visibility: "off" }]}
        ]
      });
      await this.updateLocations()
    }

    get staff():TourStaff {
      return this.tourStaff || this.tour.staff[0]
    }

    get locations() {
      let locations = []
      if(this.staff && this.staff.start_location){
        locations.push({
          ...this.staff.start_location,
          icon: 'flag-checkered',
          time: this.staff.start_time,
        })
      } else if(this.tour.start_location) {
        locations.push({
          ...this.tour.start_location,
          icon: 'flag-checkered',
          time: this.tour.start_time,
        })
      }

      for(const ps of this.tour.photo_stops){
        locations.push({
          ...ps.location,
          icon: 'camera',
          time: ps.time,
          type: 'photo_stop',
        })
      }

      for(const pb of this.tour.pass_bys){
        locations.push({
          ...pb.location,
          icon: 'eye',
          time: pb.time,
          type: 'pass_by',
        })
      }

      for(const ins of this.tour.insides){
        if(ins.inside){
          locations.push({
            ...ins.inside.location,
            icon: 'landmark',
            time: ins.start_time,
            type: 'inside',
          })
        }
      }

      for(const fnb of this.tour.fnbs){
        if(fnb.fnb) {
          locations.push({
            ...fnb.fnb.location,
            icon: 'fish',
            time: fnb.start_time,
            type: 'fnb',
          })
        }
      }

      for(const tr of this.tour.transports){
        if(tr.start_location){
          locations.push({
            ...tr.start_location,
            icon: 'plane-departure',
            time: tr.start_time,
            type: 'transport',
          })
        }
        if(tr.end_location){
          locations.push({
            ...tr.end_location,
            icon: 'plane-arrival',
            time: tr.end_time,
            type: 'transport',
          })
        }
      }

      if(this.staff && this.staff.end_location){
        locations.push({
          ...this.staff.end_location,
          icon: 'flag-checkered',
          time: this.staff.end_time,
          type: 'staff',
        })
      } else if(this.tour.end_location) {
        locations.push({
          ...this.tour.end_location,
          icon: 'flag-checkered',
          time: this.tour.end_time,
          type: 'staff',
        })
      }
      locations = _.sortBy(locations, 'time')
      return locations
    }

    get listLocations(){
      return this.locations.filter((l) => l.type == 'photo_stop' || l.type == 'pass_by')
    }

    get mapLocations(){
      return this.locations
    }

    async updateLocations() {
      this.displayedLocations = []
      const geocoder = new this.$google.maps.Geocoder()
      await Promise.all( _.map( this.mapLocations, location => {
        if( location.latitude && location.longitude ) {
          this.addMarker( location, new this.$google.maps.LatLng( location.latitude, location.longitude ) )
          return Promise.resolve()
        }

        return new Promise((resolve, reject) => {
          const request = {} as any
          request.address = location.address || location.name
          request.region = 'europe'
          if( location.city )
            request.componentRestrictions = {locality:location.city?.name}
          geocoder.geocode( request, (results, status) => {
            if (status == 'OK') {
              this.addMarker(location, results[0].geometry.location )
              const address = results[0].formatted_address.split(",").map( seg => seg.trim() ).join("\n")
              this.updateLocation(location, address, results[0].geometry.location )
              resolve(request)
            } else if(status == 'ZERO_RESULTS') {
              resolve(request)
            }
            else {
              reject('Geocode was not successful for the following reason: ' + status);
            }
          })
        }).catch((e) => {
          console.log("Geocoding error")
          return Promise.resolve()
        })
      }))
      if(this.map) this.map.fitBounds(this.bounds)
    }

    async updateLocation( location, address:string, pos:google.maps.LatLng ) {
      // TODO: need to reload tour so it will be reflected in tour state
      await Axios.post('/endpoints/tour/location.update?id='+ location.id, {data: {address:address, latitude: pos.lat(), longitude: pos.lng()}})
    }

    locationMapsLink(location){
      return `https://maps.google.com/?q=${encodeURIComponent(location.name+", " + location.address.split("\n").join(', '))}`
    }

    addMarker( location, pos: google.maps.LatLng ):google.maps.Marker {
      if(this.displayedLocations.indexOf(location.id) != -1) return
      if(this.bounds) this.bounds.extend(pos)
      this.displayedLocations.push(location.id)
      return new this.$google.maps.Marker({
        map: this.map,
        label: {text: location.name, className:`google-maps-marker-label ${location.icon}`},
        position: pos
      })
    }
  }
</script>
